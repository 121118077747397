/* HexagonButton1.css */

.hexagon-button2 {
  width: 15vmin; /* Responsive width based on smaller of viewport width or height */
  max-width: 150px; /* Maximum size to prevent excessive scaling */
  min-width: 130px; /* Optional: Minimum size to prevent being too small */
  height: 100%; /* Ensure height fills the container */
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  aspect-ratio: 1 / 1.1547; /* Maintains hexagon shape */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Ensure positioning context for absolute children */
  overflow: hidden; /* Prevent child elements from overflowing */
  -webkit-clip-path: polygon(
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%,
    50% 0%
  );
  clip-path: polygon(
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%,
    50% 0%
  );
  background-color: ; /* Optional: Background color */
  border: none; /* Remove default button border */
  padding: 0; /* Remove default padding */
}

.hexagon-button2:active {
  transform: scale(0.95);
}

.hexagon-svg2 {
  width: 100%;
  height: 100%;
}

.hexagon-label2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  pointer-events: none;
  color: #333;
}