/* src/components/Bee.css */

/* Keyframes for floating bees across the screen */
@keyframes floatAcross {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  10% {
    transform: translateX(40vw) translateY(-10px);
    opacity: 1;
  }
  20% {
    transform: translateX(80vw) translateY(10px);
    opacity: 1;
  }
  30% {
    transform: translateX(120vw) translateY(-10px);
    opacity: 1;
  }
  40% {
    transform: translateX(160vw) translateY(10px);
    opacity: 1;
  }
  50% {
    transform: translateX(200vw) translateY(-10px);
    opacity: 1;
  }
  60% {
    transform: translateX(200vw) translateY(10px);
    opacity: 1;
  }
  70% {
    transform: translateX(200vw) translateY(-10px);
    opacity: 1;
  }
  80% {
    transform: translateX(200vw) translateY(10px);
    opacity: 1;
  }
  90% {
    transform: translateX(200vw) translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: translateX(200vw) translateY(0);
    opacity: 1;
  }
}

/* Class to apply the animation to the beesolo SVG */
.beesolo-animation {
  position: absolute;
  left: -100vw; /* Start off-screen left */
  width: 33px; /* Adjust size as needed */
  height: auto;
  transform: scaleX(1); /* Ensure no flipping by default */
  animation: floatAcross linear;
  animation-iteration-count: infinite; /* Run animation infinitely */
  animation-fill-mode: none; /* Reset animation after completion */
  pointer-events: none; /* Ensure bees don't interfere with user interactions */
  will-change: transform, opacity; /* Optimize performance */
  z-index: 10; /* Ensure bees are behind other content */
}

/* Flip bees horizontally to face left */
.beesolo-animation.flipped {
  transform: scaleX(-1); /* Flip horizontally */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .beesolo-animation {
    width: 23px; /* Smaller size on mobile */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .beesolo-animation {
    width: 30px; /* Medium size on tablets */
  }
}

/* Optional: Customize Swiper navigation buttons */
.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  color: #facc15; /* Example: yellow color */
}

/* Optional: Customize Swiper pagination bullets */
.mySwiper .swiper-pagination-bullet {
  background: #facc15; /* Example: yellow color */
}

/* Optional: Adjust Swiper slide height */
.mySwiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
