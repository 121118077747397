/* src/components/LoginPage.css */

/* Import Bee styles */
@import '../../components/loginpage/HeaderFooterLayoutBees/Bee.css';

/* Hexagon Container */
.hexagon-container {
  position: relative;
  width: 90px; /* Increased width for wider hexagons */
  height: 57px; /* Maintained height */
  margin: 20px 6px; /* Adjusted margin to accommodate wider hexagons */
  display: flex;
  align-items: center;
  justify-content: center;
  /* Add a subtle drop-shadow to the container */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2));
}

/* Outer Hexagon (Border) */
.hexagon-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fbbf24; /* Border color (Tailwind's yellow-400) */
  clip-path: polygon(
    50% 0%,
    100% 20%, /* Adjusted for wider hexagon */
    100% 80%, /* Adjusted for wider hexagon */
    50% 100%,
    0% 80%,   /* Adjusted for wider hexagon */
    0% 20%    /* Adjusted for wider hexagon */
  ); /* Modified pointy-top hexagon */
  transition: background-color 0.3s, transform 0.3s;
  /* Remove box-shadow and use filter: drop-shadow instead */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.3));
}

/* Inner Hexagon */
.hexagon {
  position: absolute;
  top: 6px; /* Border width */
  left: 6px;
  width: calc(100% - 12px); /* Adjusted for border width */
  height: calc(100% - 12px); /* Adjusted for border width */
  background-color: #ffffff; /* White inside */
  clip-path: polygon(
    50% 0%,
    100% 20%, /* Adjusted for wider hexagon */
    100% 80%, /* Adjusted for wider hexagon */
    50% 100%,
    0% 80%,   /* Adjusted for wider hexagon */
    0% 20%    /* Adjusted for wider hexagon */
  ); /* Modified pointy-top hexagon */
  transition: background-color 0.3s, transform 0.3s;
  box-sizing: border-box;
  /* Remove box-shadow and use filter: drop-shadow instead */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2));
}

/* Hover Effect for Outer Hexagon (Border) */
.hexagon-container:hover::before {
  background-color: #fcd34d; /* Tailwind's yellow-300 for border on hover */
}

/* Hover Effect for Inner Hexagon */
.hexagon-container:hover .hexagon {
  background-color: #fcd34d; /* Tailwind's yellow-300 for inner hexagon on hover */
}

/* Focus Effect for Inner Hexagon */
.hexagon-container input:focus + .hexagon {
  background-color: #f59e0b; /* Tailwind's yellow-500 */
  transform: scale(1.05);
  /* Optional: Add a more pronounced shadow on focus */
  filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.3));
}

/* Input Styling */
.hexagon-container input {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.4rem; /* Increased font size for better visibility */
  color: #000000; /* Black text */
  caret-color: #b45309; /* Tailwind's yellow-800 */
  z-index: 2; /* Ensure input is on top */
  cursor: pointer; /* Change cursor to pointer for better UX */
}

/* Display Entered Character */
.hexagon-container span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  font-weight: bold;
  font-size: 1.4rem; /* Increased font size to match input */
  color: #000000; /* Black text */
  z-index: 3; /* Ensure span is above input */
}

/* Container for Hexagons */
.hexagon-inputs {
  display: flex;
  justify-content: center;
  margin-bottom: 40px; /* Large space between hexagons and submit button */
}

/* "Enter code" Text Styling */
.enter-code-text {
  text-align: center;
  font-size: 1rem; /* Slightly increased font size */
  color: #555555;
  margin-bottom: 10px;
}

/* Submit Button Styling */
.submit-button {
  width: 100%;
  padding: 14px; /* Increased padding for better touch area */
  font-weight: bold;
  color: #000000;
  background-color: #fbbf24; /* Tailwind's yellow-400 */
  border: 4px solid #fbbf24; /* Thicker border */
  border-radius: 9999px; /* Fully rounded */
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  /* Enhance shadow for the button */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.submit-button:hover {
  background-color: #fcd34d; /* Tailwind's yellow-300 */
  border-color: #fcd34d;
}

.submit-button:disabled {
  background-color: #fbbf24cc; /* Semi-transparent when disabled */
  cursor: not-allowed;
}

/* Additional Shadow Enhancements for Selected States (Optional) */

/* Example: Add a subtle shadow when a hexagon is selected */
.hexagon-container.selected::before {
  filter: drop-shadow(0 6px 10px rgba(0, 0, 0, 0.3));
}