/* src/styles/StudentHomepage.css */

.student-homepage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(to bottom right, #e0f7fa, #ffffff);
}

.student-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #00796b;
  color: white;
}

.account-button {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-item {
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.student-main {
  flex: 1;
  padding: 40px 20px;
  text-align: center;
}

.tiles-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.tile {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.tile .icon {
  margin-bottom: 10px;
  color: #00796b;
}

.tile h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #004d40;
}

.tile p {
  font-size: 0.9em;
  color: #666;
}

.student-footer {
  padding: 20px;
  background-color: #f5f5f5;
  text-align: center;
  color: #999;
}

.loading, .error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5em;
  color: #555;
}
