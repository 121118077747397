/* src/components/Bee.css */

/* Base styles for the bee image */
.beesolo {
  will-change: transform; /* Optimize performance */
  transition: transform 5s linear; /* Smooth flipping */
}

/* Flip the bee horizontally when needed */
.beesolo.flipped {
  transform: scaleX(-1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .beesolo {
    width: 23px; /* Smaller size on mobile */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .beesolo {
    width: 30px; /* Medium size on tablets */
  }
}

/* Optional: Customize Swiper navigation buttons */
.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  color: #facc15; /* Example: yellow color */
}

/* Optional: Customize Swiper pagination bullets */
.mySwiper .swiper-pagination-bullet {
  background: #facc15; /* Example: yellow color */
}

/* Optional: Adjust Swiper slide height */
.mySwiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
