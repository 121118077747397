.bee-enter {
  opacity: 0;
  transform: scale(0.3);
}

.bee-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms, transform 500ms;
}

.bee-exit {
  opacity: 1;
  transform: scale(1);
}

.bee-exit-active {
  opacity: 0;
  transform: scale(0.3);
  transition: opacity 500ms, transform 500ms;
}